import React from "react";
import theme from "theme";
import { Theme, Link, Box, Text, Icon, LinkBox, Section, Span, Image, Strong, Em } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import { FaTwitterSquare, FaGithub, FaYoutube, FaFacebook, FaInstagram } from "react-icons/fa";
import { MdPhone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Hotel Kasyna Luxor
			</title>
			<meta name={"description"} content={"Hotel Kasyno Luxor to ekskluzywny obiekt położony w sercu Warszawy. Oferujemy najwyższy standard usług i komfortowe pokoje, które zapewnią niezapomniane wrażenia. Nasze kasyno to idealne miejsce na rozrywkę i relaks."} />
			<meta property={"og:title"} content={"Hotel Kasyna Luxor"} />
			<meta property={"og:description"} content={"Hotel Kasyno Luxor to ekskluzywny obiekt położony w sercu Warszawy. Oferujemy najwyższy standard usług i komfortowe pokoje, które zapewnią niezapomniane wrażenia. Nasze kasyno to idealne miejsce na rozrywkę i relaks."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/668f95808e2e8e00217e0277/images/lobby.webp?v=2024-07-11T09:40:54.936Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6166a2b829a0a1001e6ca5fd/images/noto_snow-capped-mountain.png?v=2021-10-13T09:17:21.731Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6166a2b829a0a1001e6ca5fd/images/noto_snow-capped-mountain127.png?v=2021-10-13T09:17:28.297Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6166a2b829a0a1001e6ca5fd/images/noto_snow-capped-mountain127.png?v=2021-10-13T09:17:28.297Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6166a2b829a0a1001e6ca5fd/images/noto_snow-capped-mountain127.png?v=2021-10-13T09:17:28.297Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6166a2b829a0a1001e6ca5fd/images/noto_snow-capped-mountain127.png?v=2021-10-13T09:17:28.297Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6166a2b829a0a1001e6ca5fd/images/og%20img.png?v=2021-10-13T09:16:11.796Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Section
			background="--color-dark"
			padding="50px 0 50px 0"
			quarkly-title="Footer-19"
			border-width="0 0 .2px 0"
			border-style="solid"
			border-color="--color-darkL1"
		>
			<Box display="flex" justify-content="space-between" border-color="#232a44" md-flex-direction="column">
				<Box
					display="flex"
					md-margin="0px 0px 15px 0px"
					sm-flex-direction="column"
					justify-content="flex-start"
					align-items="center"
					grid-gap="24px"
				>
					<Box display="flex" grid-gap="8px">
						<Link
							href="tel:+9877654321223"
							color="--light"
							text-decoration-line="initial"
							font="normal 300 16px/1.5 --fontFamily-sans"
							display="block"
							margin="0px 0px 0px 0px"
							sm-margin="0px 0 5px 0px"
							hover-color="#a78bfa"
						>
							Zadzwoń do nas +48225849650
						</Link>
					</Box>
					<Box display="flex" grid-gap="8px">
						<Text margin="0px 0px 0px 0px" color="white" font="--base">
							al. Jerozolimskie 65/79, 00-001 Warszawa, Poland
						</Text>
					</Box>
				</Box>
				<Box display="flex" grid-template-columns="repeat(5, 1fr)" grid-gap="16px 24px" md-align-self="flex-start">
					<LinkBox href="/">
						<Icon
							category="fa"
							icon={FaTwitterSquare}
							size="24px"
							color="#c3c8d0"
							hover-color="#a78bfa"
							transition="background-color 1s ease 0s"
						/>
					</LinkBox>
					<LinkBox href="/">
						<Icon
							category="fa"
							icon={FaGithub}
							size="24px"
							color="#c3c8d0"
							hover-color="#a78bfa"
							transition="background-color 1s ease 0s"
						/>
					</LinkBox>
					<LinkBox href="/">
						<Icon
							category="fa"
							icon={FaYoutube}
							size="24px"
							color="#c3c8d0"
							hover-color="#a78bfa"
							transition="background-color 1s ease 0s"
						/>
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="60%"
				md-width="100%"
			>
				<Text
					font="--headline1"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					Hotel Kasyno Luxor
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="100px 0px 0px 0px"
					color="--light"
					font="--lead"
				>
					<Span
						word-break="normal"
						text-indent="0"
						color="--green"
						overflow-wrap="normal"
						white-space="normal"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Hotel Kasyno Luxor
					</Span>
					{" "}to ekskluzywny obiekt położony w sercu Warszawy. Oferujemy najwyższy standard usług i komfortowe pokoje, które zapewnią niezapomniane wrażenia. Nasze kasyno to idealne miejsce na rozrywkę i relaks.
				</Text>
			</Box>
		</Section>
		<Section padding="60px 0 60px 0" border-color="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" sm-flex-wrap="wrap" />
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				display="flex"
				sm-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					min-width="600px"
					src="https://uploads.quarkly.io/668f95808e2e8e00217e0277/images/img10.jpg?v=2024-07-11T09:40:45.068Z"
					max-height="600px"
					object-fit="cover"
					lg-max-height="350px"
					lg-max-width="100%"
					width="100%"
					lg-width="100%"
					lg-min-width="350px"
					srcSet="https://smartuploads.quarkly.io/668f95808e2e8e00217e0277/images/img10.jpg?v=2024-07-11T09%3A40%3A45.068Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668f95808e2e8e00217e0277/images/img10.jpg?v=2024-07-11T09%3A40%3A45.068Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668f95808e2e8e00217e0277/images/img10.jpg?v=2024-07-11T09%3A40%3A45.068Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668f95808e2e8e00217e0277/images/img10.jpg?v=2024-07-11T09%3A40%3A45.068Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668f95808e2e8e00217e0277/images/img10.jpg?v=2024-07-11T09%3A40%3A45.068Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668f95808e2e8e00217e0277/images/img10.jpg?v=2024-07-11T09%3A40%3A45.068Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668f95808e2e8e00217e0277/images/img10.jpg?v=2024-07-11T09%3A40%3A45.068Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				width="50%"
				background="--color-green"
				display="flex"
				flex-direction="column"
				lg-padding="24px 24px 24px 24px"
				empty-border-color="LightGray"
				align-items="center"
				justify-content="center"
				empty-min-width="64px"
				empty-border-style="solid"
				padding="86px 86px 86px 86px"
				sm-width="100%"
			>
				<Text
					margin="0px 0px 32px 0px"
					color="--dark"
					font="--headline4"
					lg-font="700 22px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					lg-margin="0px 0px 16px 0px"
				>
					Poznaj historię Kasyna Luxor
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
				>
					Hotel Kasyno Luxor to miejsce, gdzie historia spotyka się z nowoczesnością. Nasze kasyno oferuje szeroki wybór gier, które dostarczą niezapomnianych emocji i wrażeń. Profesjonalna obsługa i luksusowe wnętrza sprawią, że każda wizyta będzie wyjątkowa.
				</Text>
			</Box>
		</Section>
		<Section padding="120px 0 120px 0" md-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
			>
				<Text
					font="--headline2"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					Najlepsze oferty Hotelu Kasyna Luxor
				</Text>
				<Text
					color="--light"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="0px 0px 0px 0px"
				>
					Nasze ekskluzywne oferty to gwarancja niezapomnianych wrażeń. Zarezerwuj pobyt w Hotelu Kasyna Luxor i ciesz się luksusem oraz wyjątkowymi atrakcjami. Oferujemy różnorodne pakiety, które spełnią oczekiwania każdego gościa.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/668f95808e2e8e00217e0277/images/img7.jpg?v=2024-07-11T09:40:45.056Z"
					width="100%"
					max-height="293px"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					srcSet="https://smartuploads.quarkly.io/668f95808e2e8e00217e0277/images/img7.jpg?v=2024-07-11T09%3A40%3A45.056Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668f95808e2e8e00217e0277/images/img7.jpg?v=2024-07-11T09%3A40%3A45.056Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668f95808e2e8e00217e0277/images/img7.jpg?v=2024-07-11T09%3A40%3A45.056Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668f95808e2e8e00217e0277/images/img7.jpg?v=2024-07-11T09%3A40%3A45.056Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668f95808e2e8e00217e0277/images/img7.jpg?v=2024-07-11T09%3A40%3A45.056Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668f95808e2e8e00217e0277/images/img7.jpg?v=2024-07-11T09%3A40%3A45.056Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668f95808e2e8e00217e0277/images/img7.jpg?v=2024-07-11T09%3A40%3A45.056Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="60px 0 40px 0" lg-padding="50px 0 25px 0" md-padding="30px 0 25px 0">
			<Override slot="SectionContent" align-items="flex-end" />
			<Text
				margin="0px 0px 16px 0px"
				color="--light"
				font="--headline2"
				lg-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
				md-font="normal 500 22px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
				width="100%"
			>
				Nasze Kasyno
			</Text>
			<Text
				color="--light"
				font="--headline2"
				lg-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
				width="100%"
				margin="0px 0px 0px 0px"
			>
				<Em>
					<Strong>
						Ekscytujące Kasyno na Miejscu
					</Strong>
				</Em>
			</Text>
			<Box
				empty-min-height="64px"
				empty-border-color="LightGray"
				width="50%"
				empty-min-width="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				margin="40px 0px 0px 0px"
				lg-width="70%"
				md-width="100%"
			>
				<Text margin="0px 0px 16px 0px" color="--light" font="--base" text-align="left">
					Hotel Kasyno Luxor zaprasza do naszego nowoczesnego kasyna, które oferuje niezrównane doświadczenia hazardowe. Nasze kasyno jest wyposażone w najnowsze automaty do gier, stoły do blackjacka, ruletki, pokera i wiele innych. Dla tych, którzy cenią sobie ekskluzywność, oferujemy także prywatne pokoje do gry, gdzie można cieszyć się grą w bardziej kameralnej atmosferze. Nasze profesjonalne krupierzy zapewnią, że każda gra przebiega płynnie i zgodnie z najwyższymi standardami.
				</Text>
				<Box display="grid" grid-template-columns="1fr 1fr" grid-gap="0px 40px">
					<Box>
						<Image
							src="https://images.unsplash.com/photo-1518895312237-a9e23508077d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
							max-height="120px"
							width="100%"
							object-fit="cover"
							lg-height="120px"
							srcSet="https://images.unsplash.com/photo-1518895312237-a9e23508077d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1518895312237-a9e23508077d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1518895312237-a9e23508077d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1518895312237-a9e23508077d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1518895312237-a9e23508077d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1518895312237-a9e23508077d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1518895312237-a9e23508077d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Text color="--green" lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif" margin="8px 0px 0px 0px" font="--lead">
							Turnieje i Wydarzenia Specjalne
						</Text>
					</Box>
					<Box>
						<Image
							lg-height="120px"
							src="https://images.unsplash.com/photo-1602081115720-72e5b0a254b8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
							max-height="120px"
							width="100%"
							object-fit="cover"
							srcSet="https://images.unsplash.com/photo-1602081115720-72e5b0a254b8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1602081115720-72e5b0a254b8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1602081115720-72e5b0a254b8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1602081115720-72e5b0a254b8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1602081115720-72e5b0a254b8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1602081115720-72e5b0a254b8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1602081115720-72e5b0a254b8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Text margin="8px 0px 0px 0px" font="--lead" color="--green" lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif">
							Bar Kasynowy i Lounge
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="60px 0 40px 0">
			<Box
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
			>
				<Text
					margin="0px 0px 40px 0px"
					color="--light"
					font="--headline2"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 16px 0px"
				>
					Przygoda zaczyna się
				</Text>
				<Text
					color="--light"
					font="--base"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					margin="0px 0px 0px 0px"
					text-align="left"
				>
					Każdego ranka powitają Cię aromaty świeżo parzonej kawy i odgłosy rwącej wody, które mieszają się z szeptem budzącego się do życia miasta. Śniadanie jest gorące i różnorodne, czekające na Ciebie. Po śniadaniu, pomóż w załadowaniu ekwipunku z powrotem do łodzi.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" md-padding="20px 0 12px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Image
					object-fit="cover"
					lg-max-height="230px"
					src="https://uploads.quarkly.io/668f95808e2e8e00217e0277/images/img5.jpg?v=2024-07-11T09:40:45.104Z"
					max-height="400px"
					width="100%"
					srcSet="https://smartuploads.quarkly.io/668f95808e2e8e00217e0277/images/img5.jpg?v=2024-07-11T09%3A40%3A45.104Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668f95808e2e8e00217e0277/images/img5.jpg?v=2024-07-11T09%3A40%3A45.104Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668f95808e2e8e00217e0277/images/img5.jpg?v=2024-07-11T09%3A40%3A45.104Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668f95808e2e8e00217e0277/images/img5.jpg?v=2024-07-11T09%3A40%3A45.104Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668f95808e2e8e00217e0277/images/img5.jpg?v=2024-07-11T09%3A40%3A45.104Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668f95808e2e8e00217e0277/images/img5.jpg?v=2024-07-11T09%3A40%3A45.104Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668f95808e2e8e00217e0277/images/img5.jpg?v=2024-07-11T09%3A40%3A45.104Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					Luksusowe Zakwaterowanie
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Hotel Kasyno Luxor oferuje luksusowe pokoje i apartamenty, które zapewniają najwyższy komfort i wygodę. Każdy pokój jest elegancko urządzony, wyposażony w najnowsze udogodnienia, w tym telewizory z płaskim ekranem, bezpłatne Wi-Fi oraz mini-bary. Niezależnie od tego, czy podróżujesz w celach biznesowych, czy rekreacyjnych, nasze pokoje zapewnią ci relaksujący i spokojny pobyt. Zapraszamy do skorzystania z naszej obsługi pokojowej dostępnej przez całą dobę.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
			>
				<Image
					src="https://images.unsplash.com/photo-1561214078-f3247647fc5e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					max-height="400px"
					width="100%"
					object-fit="cover"
					lg-max-height="230px"
					srcSet="https://images.unsplash.com/photo-1561214078-f3247647fc5e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1561214078-f3247647fc5e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1561214078-f3247647fc5e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1561214078-f3247647fc5e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1561214078-f3247647fc5e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1561214078-f3247647fc5e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1561214078-f3247647fc5e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
					sm-margin="24px 0px 16px 0px"
				>
					Nowoczesne Centrum Fitness
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					text-align="left"
				>
					Dla tych, którzy chcą zachować formę podczas pobytu, oferujemy nowoczesne centrum fitness wyposażone w najnowsze urządzenia treningowe. Znajdziesz tu sprzęt do treningu siłowego i cardio, a także przestrzeń do jogi i pilatesu. Nasze centrum fitness jest otwarte przez całą dobę, aby dostosować się do twojego harmonogramu. Dodatkowo, nasi doświadczeni trenerzy personalni są dostępni, aby pomóc ci osiągnąć twoje cele fitness.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" md-padding="20px 0 12px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				lg-width="40%"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-min-width="64px"
				empty-border-color="LightGray"
				width="30%"
			>
				<Image
					src="https://images.unsplash.com/photo-1602774895312-f002b8f2f33b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
					max-height="400px"
					width="100%"
					object-fit="cover"
					lg-max-height="230px"
					srcSet="https://images.unsplash.com/photo-1602774895312-f002b8f2f33b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1602774895312-f002b8f2f33b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1602774895312-f002b8f2f33b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1602774895312-f002b8f2f33b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1602774895312-f002b8f2f33b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1602774895312-f002b8f2f33b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1602774895312-f002b8f2f33b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
				>
					Basen i SPA
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Odkryj nasz luksusowy basen i spa, idealne miejsce na relaks i odnowę biologiczną. Nasz basen z podgrzewaną wodą jest dostępny przez cały rok, a leżaki wokół basenu zapewniają doskonałe miejsce do opalania się. W naszym spa oferujemy szeroki wybór zabiegów, w tym masaże, zabiegi na twarz i ciało oraz sauny i łaźnie parowe. Skorzystaj z naszych pakietów wellness, aby poczuć się odświeżonym i odmłodzonym.
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
				md-width="100%"
				lg-width="60%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-style="solid"
				empty-border-width="1px"
				width="70%"
				md-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://images.unsplash.com/photo-1537047902294-62a40c20a6ae?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
					max-height="400px"
					width="100%"
					object-fit="cover"
					lg-max-height="230px"
					object-position="bottom"
					srcSet="https://images.unsplash.com/photo-1537047902294-62a40c20a6ae?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1537047902294-62a40c20a6ae?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1537047902294-62a40c20a6ae?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1537047902294-62a40c20a6ae?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1537047902294-62a40c20a6ae?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1537047902294-62a40c20a6ae?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1537047902294-62a40c20a6ae?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Box sm-padding="0px 0px 0px 0px" md-padding="0px 0px 0px 0px" padding="0px 30% 0px 0px">
					<Text
						md-margin="12px 0px 16px 0px"
						sm-margin="24px 0px 16px 0px"
						margin="32px 0px 16px 0px"
						font="--lead"
						color="--green"
						lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					>
						Wyśmienite Restauracje i Bary
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="--base"
						color="--light"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
						text-align="left"
					>
						Hotel Kasyno Luxor szczyci się wyśmienitą ofertą kulinarną. Nasze restauracje serwują dania kuchni międzynarodowej i lokalnej, przygotowywane przez wykwalifikowanych szefów kuchni. Każdy posiłek to prawdziwa uczta dla zmysłów. Nasze bary oferują szeroki wybór napojów, od klasycznych koktajli po najlepsze wina i whisky. Zapraszamy do naszych restauracji i barów, aby cieszyć się wyjątkowym doświadczeniem kulinarnym w eleganckim otoczeniu.
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 50px 0" background="--color-dark" quarkly-title="Footer-13" sm-padding="50px 0 50px 0">
			<Box
				min-width="100px"
				min-height="100px"
				padding="50px 30px 50px 30px"
				background="linear-gradient(180deg,rgba(11, 1, 1, 0.67) 0.5%,rgba(11, 7, 7, 0.45) 99%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/668f95808e2e8e00217e0277/images/img14.webp?v=2024-07-11T09:40:35.013Z) bottom/auto repeat scroll padding-box"
				display="flex"
				justify-content="space-around"
				align-items="center"
				margin="0px 0px 80px 0px"
				md-flex-direction="column"
				md-align-items="stretch"
				md-margin="0px 0px 50px 0px"
				md-padding="30px 30px 30px 30px"
				sm-padding="20px 15px 20px 15px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					md-margin="0px 0px 30px 0px"
					md-width="100%"
					sm-margin="0px 0px 18px 0px"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="normal 400 42px/1.2 --fontFamily-sansHelvetica"
						color="--light"
						md-margin="0px 0px 15px 0px"
						sm-margin="0px 0px 12px 0px"
					>
						Zadzwoń po rezerwację
					</Text>
					<Text margin="0px 0px 0px 0px" color="--light" font="normal 300 20px/1.5 --fontFamily-sans">
						Jesteśmy otwarci w godz
Od poniedziałku do piątku (10:00 – 18:00) i soboty (10:00 – 18:00)
					</Text>
				</Box>
				<LinkBox
					flex-direction="row"
					padding="15px 25px 15px 25px"
					border-width="2px"
					border-style="solid"
					border-color="--color-light"
					background="rgba(247, 251, 255, 0.12)"
					sm-padding="10px 15px 10px 15px"
					href="tel:+48225849650"
				>
					<Icon
						category="md"
						icon={MdPhone}
						size="24px"
						color="--light"
						margin="0px 8px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 500 20px/1.5 --fontFamily-sans" color="--light">
						+48225849650
					</Text>
				</LinkBox>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				margin="0px 0px 80px 0px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
				md-margin="0px 0px 50px 0px"
			>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 25px 0px"
						font="normal 500 24px/1.2 --fontFamily-sansHelvetica"
						text-align="center"
						color="#ba653f"
						md-margin="0px 0px 20px 0px"
					>
						Adres
					</Text>
					<Text
						margin="0px 0px 20px 0px"
						color="#d7c7c5"
						font="normal 300 18px/1.5 --fontFamily-sans"
						text-align="center"
						border-color="#b8acac"
						md-margin="0px 0px 15px 0px"
					>
						al. Jerozolimskie 65/79, 00-001 Warszawa, Poland
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						grid-template-columns="repeat(5, 1fr)"
						grid-gap="16px 24px"
						md-align-self="flex-start"
						sm-align-self="center"
						justify-items="start"
						justify-content="center"
					>
						<LinkBox href="/">
							<Icon
								category="fa"
								icon={FaFacebook}
								size="24px"
								color="--light"
								hover-color="--lightD2"
								transition="background-color 1s ease 0s"
							/>
						</LinkBox>
						<LinkBox href="/">
							<Icon
								category="fa"
								icon={FaInstagram}
								size="24px"
								color="--light"
								hover-color="--lightD2"
								transition="background-color 1s ease 0s"
							/>
						</LinkBox>
						<LinkBox href="/">
							<Icon
								category="fa"
								icon={FaTwitterSquare}
								size="24px"
								color="--light"
								hover-color="--lightD2"
								transition="background-color 1s ease 0s"
							/>
						</LinkBox>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Text
						margin="0px 0px 25px 0px"
						font="normal 500 24px/1.2 --fontFamily-sansHelvetica"
						text-align="center"
						color="#ba653f"
						md-margin="0px 0px 20px 0px"
					>
						Rezerwacja
					</Text>
					<Link
						href="tel:+48225849650"
						color="#d7c7c5"
						text-decoration-line="initial"
						font="normal 300 18px/1.5 --fontFamily-sans"
						display="flex"
						text-align="center"
						margin="0px 0px 15px 0px"
						hover-color="#f5eceb"
					>
						+48225849650
					</Link>
					<Link
						href="mailto:info@yourdomain.com"
						color="#d7c7c5"
						text-decoration-line="initial"
						font="normal 300 18px/1.5 --fontFamily-sans"
						display="flex"
						margin="0px 0px 15px 0px"
						text-align="center"
						hover-color="#f5eceb"
					>
						info@braliver-hotel.com
					</Link>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 25px 0px"
						font="normal 500 24px/1.2 --fontFamily-sansHelvetica"
						text-align="center"
						color="#ba653f"
						md-margin="0px 0px 20px 0px"
					>
						Godzina otwarcia
					</Text>
					<Text
						margin="0px 0px 15px 0px"
						color="#d7c7c5"
						font="normal 300 18px/1.5 --fontFamily-sans"
						text-align="center"
						border-color="#b8acac"
					>
						Od poniedziałku do piątku (10:00 – 23:00)
					</Text>
					<Text
						margin="0px 0px 20px 0px"
						color="#d7c7c5"
						font="normal 300 18px/1.5 --fontFamily-sans"
						text-align="center"
						border-color="#b8acac"
					>
						Sobota (10:00 – 22:00)
					</Text>
				</Box>
			</Box>
			<Text
				margin="0px 0px 0px 0px"
				font="normal 300 18px/1.5 --fontFamily-sans"
				color="#d7c7c5"
				md-margin="0px 0px 25px 0px"
				sm-text-align="center"
				text-align="center"
			>
				© 2024 Hotel Kasyno Luxor All rights reserved.
			</Text>
		</Section>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});